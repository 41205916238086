import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { searchSchedulesBoleto } from "../../../services/banking";
import { ScheduleBoleto } from "../../../typings/ScheduleBoleto";
import "./styles.scss"

const SchedulesBoletoPage = () => {

    const navigate = useNavigate();
    const [schedules, setSchedules] = useState<ScheduleBoleto[]>([]);
    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        fetchSchedulesBoleto();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const fetchSchedulesBoleto = async () => {
        console.log(`fetchSchedulesBoleto...`)
        setLoading(true);
        try {
            const res = await searchSchedulesBoleto(page, searchText);
            if (res.data) {
                setSchedules(res.data)
            }
        } catch (error) {
            console.log(error)
        }
        setLoading(false);
    }

    return (
        <div className="container-fluid">
            <div className="card my-4 p-4">
                <div className="row mb-4">
                    <h1 className="mb-4">Agendamentos de Boleto</h1>
                    <div className="d-flex" style={{ gap: "20px" }}>
                        <div className="search-container">
                            <input className="search-input" type="text"
                                value={searchText} onChange={e => setSearchText(e.target.value)} onKeyDown={e => e.key === 'Enter' && fetchSchedulesBoleto()} />
                        </div>
                    </div>
                </div>
                {loading ?
                    <div className="text-center mt-5">
                        Carregando...
                    </div>
                    :
                    (<div>
                        {(schedules && schedules.length > 0) ?
                            <div>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Beneficiário</th>
                                            <th scope="col">Agendado para</th>
                                            <th scope="col">Valor Pagamento</th>
                                            <th scope="col">Valor Título</th>
                                            <th scope="col">Linha digitável</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {schedules.map(schedule =>
                                            <tr
                                                className="interactive-table-row"
                                                onClick={() => navigate("/scheduled-boleto?id=" + schedule.id)}
                                                key={schedule.id}
                                            >
                                                <th scope="row">{schedule.id}</th>
                                                <td>{schedule.pagador_conta} {schedule.pagador_conta_digito}</td>
                                                <td>{dayjs(schedule.scheduled_date).format("DD/MM/YYYY HH:mm")}</td>
                                                <td>{schedule.valor_pagamento}</td>
                                                <td>{schedule.valor_titulo}</td>
                                                <td>{schedule.titulo_linha_digitavel}</td>
                                                <td>
                                                    <button type="button" className={`btn btn-sm ${(schedule.status === "completed") ? 'btn-success' : ((schedule.status === "not_completed") ? 'btn-danger' : 'btn-warning')}`}>
                                                        {(schedule.status === "completed") ? 'concluído' : ((schedule.status === "not_completed") ? 'erro' : 'pendente')}
                                                    </button></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <div className="text-center mt-5">
                                Não foi encontrado nenhum agendamento
                            </div>
                        }
                        <div className="text-center px-3 pt-3">
                            {page > 1 && <button className="page-selector" onClick={() => setPage(page - 1)}>{"<"}</button>}
                            <label className="page-selector">
                                {page}
                            </label>
                            {schedules.length > 19 && <button className="page-selector" onClick={() => setPage(page + 1)}>{">"}</button>}
                        </div>
                    </div>)
                }
            </div>
        </div >
    )
}

export default SchedulesBoletoPage;